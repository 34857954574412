import React, {Component} from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} tech agency </title>
          <meta name="description" content="We don't just design for tech, we live it. From landing pages to big CMS.
          We project consistent brand identities by ensuring every image and icon is working in harmony with your values."/>
        </Helmet>
      </React.Fragment>
    )
  }
}


export default PageHelmet;
