import React, {Component} from "react";
import {FiEdit3, FiCode, FiMessageSquare, FiSmartphone} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiEdit3/>,
    title: 'Design',
    description: 'We project consistent brand identities by ensuring every image and icon is working in harmony with your values.'
  },
  {
    icon: <FiCode/>,
    title: 'Website Development',
    description: 'Our special sauce. We don\'t just design for tech, we live it. From landing pages to big CMS.'
  },
  {
    icon: <FiSmartphone/>,
    title: 'Mobile App Development',
    description: 'Sometimes you need more than a mobile website. You need to be inside your customer smartphone. We can help you with that.'
  },
  {
    icon: <FiMessageSquare/>,
    title: 'Chatbot',
    description: 'The future is now. Automatize your support center and give more assertive and interactive responses to you customer.'
  },
];

class ServiceTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">Services</h2>
              {/*<p>{description}</p>*/}
              <div className="service-btn">
                {/*<a className="btn-transparent rn-btn-dark" href="/service">*/}
                  {/*<span className="text">Request Custom Service</span>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <div className="service service__style--2">
                      <div className="icon">
                        {val.icon}
                      </div>
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ServiceTwo;
