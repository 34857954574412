import React, {Component} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import About from "../component/HomeLayout/homeOne/AboutTwo";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

class Mangocorp extends Component {
  render() {
    return (
      <div className="active-dark">
        <Helmet pageTitle="mango,corp."/>

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne/>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area about-position-top pb--120 bg_color--1">
          <About/>
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
          <div className="container">
            <ServiceTwo/>
          </div>
        </div>
        {/* End Service Area  */}


        {/* Start Brand Area */}
        <div className="rn-brand-area brand-separation pb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo/>
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer/>

      </div>
    )
  }
}

export default Mangocorp;