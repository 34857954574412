import React, {Component} from "react";
import {FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
  // {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
  {Social: <FaLinkedinIn/>, link: 'https://www.linkedin.com/company/mango-corp/', title: "mango,corp. LikendIn"},
  // {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
  // {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This</span>
                    <h2>Let's get <br/> to work</h2>
                    <a className="rn-button-style--2" href="mailto:hello@mangocorp.dev">
                      <span>Contact Us</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Say Hello</h4>
                        <ul className="ft-link">
                          <li><a href="mailto:hello@mangocorp.dev">hello@mangocorp.dev</a></li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}><a href={`${val.link}`} title={val.title} ref="noopener">{val.Social}</a></li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        {/*<h4>Quick Link</h4>*/}
                        {/*<ul className="ft-link">*/}
                        {/*<li><a href="#work">Work</a></li>*/}
                        {/*<li><a href="#about">Services</a></li>*/}
                        {/*<li><a href="#clients">Clients</a></li>*/}
                        {/*</ul>*/}
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>mango,corp. is a company registered in São Paulo, Brazil</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer;