import React, {Component} from "react";
import { Link } from 'react-router-dom';

class SliderOne extends Component {
  render() {
    return (
      <div className="slider-activation">
        {/* Start Single Slide */}
        <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
             data-black-overlay="6">
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner mangolin">
                  <h2 className="title theme-gradient theme-gradient-green green">MAN<br/>GO<br/>LIN</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h3 className='title'>is now</h3>
                  <h1 className="title theme-gradient">mango,<br/> Corp. </h1>
                </div>
              </div>
            </div>
            <Link to="/" className="rn-button-style--2">
              <span>Go to the new website</span>
            </Link>
            {/* End Service Area */}
          </div>
        </div>
        {/* End Single Slide */}
      </div>
    );
  }
}

export default SliderOne;