import React, {Component} from "react";
import Footer from "../component/footer/FooterTwo";

import SliderOneMangolin from "../component/slider/SliderOneMangolin";
import Helmet from "../component/common/Helmet";

class Mangolin extends Component {
  render() {
    return (
      <div className="active-dark">
        <Helmet pageTitle="mangolin is now mango,corp."/>

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOneMangolin/>
        </div>
        {/* End Slider Area   */}

        <Footer/>

      </div>
    )
  }
}

export default Mangolin;