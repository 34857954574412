// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

// Create Import File
import './index.scss';

// Dark Home Layout 
import Mangocorp from './dark/Mangocorp';
import Mangolin from './dark/Mangolin';

// Element Layout
import error404 from "./elements/error404";

import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component {
  render() {
    ReactGA.initialize('UA-164844438-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
      <BrowserRouter basename={'/'}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Mangocorp}/>

          <Route exact path={`${process.env.PUBLIC_URL}/mangolin`} component={Mangolin}/>

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
          <Route component={error404}/>
        </Switch>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();