import React, {Component} from "react";
import Particles from 'react-particles-js';

class AboutTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">

              <div className="col-lg-5 col-md-12">
                <div className="thumbnail particles">
                  <Particles width="550px" height="600px"/>
                  {/*<img className="w-100" src="/assets/images/about/global.png" alt="About Images"/>*/}
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About</h2>
                    <p className="description">We all started out as freelancers working alone, delivering only a small
                      range of solutions. Now we come together to be able to deliver what we do best, but in a much
                      wider range.</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Who we are</h3>
                        <p>People. We are people. People around the world working to create and innovate together.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Where we are</h3>
                        <p>We are in São Paulo, Rio, Berlin, Barcelona, Santo Domingo and London. We are online.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AboutTwo;