import React, {Component} from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <h2>Trusted by</h2>
        <ul className="brand-style-2">
          <li>
            <a href="https://adventures.do/" target="_blank" title="AdVentures Digital Agency" rel="noopener">
              <img src="/assets/images/brand/adventures2.png" alt="AdVentures Digital Agency"/>
            </a>
          </li>
          <li>
            <a href="https://www.agenciabadaro.com.br/" target="_blank" title="Badaró" rel="noopener">
              <img src="/assets/images/brand/badaro.png" alt="Badaró"/>
            </a>
          </li>
          <li>
            <a href="http://inpress30.com.br/" target="_blank" title="In Press" rel="noopener">
              <img src="/assets/images/brand/inpress2.png" alt="In Press"/>
            </a>
          </li>
          <li>
            <a href="https://relissan.com.br/" target="_blank" title="Relissan" rel="noopener">
              <img src="/assets/images/brand/relissan.png" alt="Relissan"/>
            </a>
          </li>
          <li>
            <a href="https://slingshot.com.br/" target="_blank" title="Slingshot Digital" rel="noopener">
              <img src="/assets/images/brand/slingshotdigital.png" alt="Slingshot Digital"/>
            </a>
          </li>
          {/*<li>*/}
            {/*<a href="https://fi.holdings/" target="_blank" title="Fi Holdings">*/}
              {/*<img src="/assets/images/brand/fi-logo.svg" alt="Fi Holdings"/>*/}
            {/*</a>*/}
          {/*</li>*/}
          {/*<li>*/}
            {/*<a href="https://fi.holdings/" target="_blank" title="Paris Filmes">*/}
              {/*<img src="/assets/images/brand/paris-filmes.png" alt="Paris Filmes"/>*/}
            {/*</a>*/}
          {/*</li>*/}
        </ul>
      </React.Fragment>
    )
  }
}

export default BrandTwo;