import React, {Component} from "react";
import {FiLayout, FiLayers, FiCodesandbox} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiLayers/>,
    title: 'Full Stack Services',
    description: 'Access our full stack of creative and tech services for all forms of media production.'
  },
  {
    icon: <FiLayout/>,
    title: 'Rich User Experience\n',
    description: 'Our processes and operations are strategically geared towards providing the best UX for you and your customers.'
  },
  {
    icon: <FiCodesandbox/>,
    title: 'Innovation',
    description: 'You can always be rest assured that we employ the latest technologies, trends and practices to develop our products.'
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  {val.icon}
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default ServiceOne;